import { For } from 'solid-js'
import { useStore } from '@nanostores/solid'
import { navigate } from 'astro:transitions/client'

import {
  ArrowLeft,
  BookListItem,
  StudentListItem,
  Typography,
} from '@/components'
import {
  setAssignmentProgress,
  storeSelectedBooks,
  storeSelectedStudents,
} from '@/store'
import { colors } from '@/styles/theme/colors'
import { cn } from '@/utils'

function AssignmentConfirmation() {
  const selectedBooks = useStore(storeSelectedBooks)
  const selectedStudents = useStore(storeSelectedStudents)

  function handleBack() {
    setAssignmentProgress({
      name: 'books',
      url: '/assignment/books',
    })
    navigate('/assignment/books')
  }

  function handleBackBooks() {
    setAssignmentProgress({
      name: 'books',
      url: '/assignment/books',
    })
    navigate('/assignment/books')
  }

  function handleBackStudents() {
    setAssignmentProgress({
      name: 'students',
      url: '/assignment',
    })
    navigate('/assignment')
  }

  return (
    <div class="flex w-full flex-col gap-4">
      <button
        class={cn(
          'flex h-7 min-h-7 w-7 min-w-7 items-center justify-center rounded-full border border-solid border-primary',
        )}
        onClick={handleBack}
      >
        <ArrowLeft
          class="relative"
          color={colors.primary}
          width={16}
          height={16}
        />
      </button>

      <div class="flex w-full flex-col items-center justify-center gap-6">
        <div class="flex w-full flex-col gap-1">
          <Typography element="h2" size="t-lg">
            Selected Students
          </Typography>
          <button
            class="btn-primary-edsoma h-6 w-28 text-xs font-normal"
            onClick={handleBackStudents}
          >
            Back to students
          </button>

          <div class="flex max-h-60 flex-col gap-1 overflow-y-auto rounded-md border border-solid border-gray-100 px-4 py-6 shadow-md">
            <For each={selectedStudents()}>
              {(student) => (
                <StudentListItem
                  student={student}
                  selectable
                  selectedStudents={selectedStudents()}
                />
              )}
            </For>
          </div>
        </div>

        <div class="flex w-full flex-col gap-1">
          <Typography element="h2" size="t-lg">
            Selected Books
          </Typography>
          <button
            class="btn-primary-edsoma h-6 w-28 text-xs font-normal"
            onClick={handleBackBooks}
          >
            Back to books
          </button>

          <div class="flex max-h-60 flex-col gap-1 overflow-y-auto rounded-md border border-solid border-gray-100 px-4 py-6 shadow-md">
            <For each={selectedBooks()}>
              {(book) => (
                <BookListItem book={book} selectedBooks={selectedBooks()} />
              )}
            </For>
          </div>
        </div>
      </div>

      <div class="sticky bottom-0 z-30 -mb-6 flex items-center justify-center bg-white pb-6 pt-4 md:justify-end">
        <button
          class="btn-primary-edsoma h-10 w-48"
          // disabled={!hasSelectedBooks()}
          // onClick={continueAssignment}
        >
          Confirm
        </button>
      </div>
    </div>
  )
}

export default AssignmentConfirmation
