import { action } from 'nanostores'
import { persistentAtom } from '@nanostores/persistent'

import type { Student } from '@/core/entities'

export const storeSelectedStudents = persistentAtom<Student[]>(
  'storeSelectedStudents',
  [],
  {
    encode(value) {
      return JSON.stringify(value)
    },
    decode(value) {
      try {
        return JSON.parse(value)
      } catch (error) {
        return value
      }
    },
  },
)

export const handleSelectStudent = action(
  storeSelectedStudents,
  'handleSelectedStudent',
  (store, student: Student) => {
    if (store.get().some((storedStudent) => storedStudent.id === student.id)) {
      store.set(
        store.get().filter((storedStudent) => storedStudent.id !== student.id),
      )
      return
    }
    store.set([...store.get(), student])
  },
)

export const setSelectedStudents = action(
  storeSelectedStudents,
  'setSelectedStudents',
  (store, students: Student[]) => {
    store.set(students)
  },
)
