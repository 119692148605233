import { BiSolidDashboard } from 'solid-icons/bi'
import {
  IoBook,
  IoDocumentText,
  IoHelpCircle,
  IoMail,
  IoSchool,
  IoShield,
} from 'solid-icons/io'

import { cn } from '@/utils'
import { For, type JSX, Show } from 'solid-js'

interface SideMenuLinks {
  title: string
  href: string
  icon: JSX.Element
}

function SideMenu(props: { drawer?: boolean }) {
  const links: SideMenuLinks[] = [
    {
      title: 'Dashboard',
      href: '/home',
      icon: <BiSolidDashboard />,
    },
    {
      title: 'Students',
      href: '/students',
      icon: <IoSchool />,
    },
    {
      title: 'Books Assignment',
      href: '/assignment',
      icon: <IoBook />,
    },
    {
      title: 'FAQ',
      href: '/faq',
      icon: <IoHelpCircle />,
    },
    {
      title: 'Terms and Conditions',
      href: '/terms',
      icon: <IoDocumentText />,
    },
    {
      title: 'Privacy Policy',
      href: '/privacy-policy',
      icon: <IoShield />,
    },
    {
      title: 'California Privacy Policy',
      href: '/california-policy',
      icon: <IoShield />,
    },
    {
      title: 'Contact Us',
      href: '/contact',
      icon: <IoMail />,
    },
  ]

  return (
    <aside
      id="logo-sidebar"
      class={cn(
        'fixed left-0 top-0 z-40 h-screen w-60 -translate-x-full shadow-lg transition-transform sm:translate-x-0 ',
        props.drawer ? '' : 'hidden lg:block',
      )}
      aria-label="Sidebar"
    >
      <div class="relative h-full w-full overflow-y-auto bg-white px-3 py-4">
        <Show when={props.drawer}>
          <label
            for="side-menu-drawer"
            aria-label="close sidebar"
            class="drawer-overlay absolute right-1 top-1 cursor-pointer checked:bg-edsm-neutral-100 checked:opacity-50"
          >
            <img src="/close.svg" alt="close drawer" class="w-7" />
          </label>
        </Show>

        <a href="" class="mb-5 flex items-center justify-center">
          <img src="/brand.svg" alt="logo" class="w-32" />
        </a>
        <ul class="space-y-2 font-medium">
          <For each={links}>
            {(link) => (
              <li>
                <a
                  href={link.href}
                  class={cn(
                    'group flex items-center rounded-lg p-2 text-gray-900',
                    window.location.pathname.includes(link.href)
                      ? 'bg-primary text-white hover:opacity-90'
                      : 'hover:bg-gray-200',
                  )}
                >
                  {link.icon}
                  <span class="ms-3">{link.title}</span>
                </a>
              </li>
            )}
          </For>
        </ul>
      </div>
    </aside>
  )
}

export default SideMenu
