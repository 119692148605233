import { SideMenu } from '@/components'

function SideMenuDrawer() {
  return (
    <div class="drawer absolute lg:hidden">
      <input id="side-menu-drawer" type="checkbox" class="drawer-toggle" />
      <div class="drawer-content" />
      <div class="drawer-side z-40">
        <label
          for="side-menu-drawer"
          aria-label="close sidebar"
          class="drawer-overlay checked:bg-edsm-neutral-100 checked:opacity-50"
        />

        <SideMenu drawer />
      </div>
    </div>
  )
}

export default SideMenuDrawer
