---
import brand from '@/assets/brand.svg?url'
import { Image } from 'astro:assets'
---

<header class="px-8 pt-8 flex flex-row items-end justify-center w-full">
  <Image
    src={brand}
    alt="Kid with a book"
    loading="lazy"
    width={191}
    height={72}
    class="h-md:w-28"
  />
</header>
