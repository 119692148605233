import { persistentAtom } from '@nanostores/persistent'

import type { Book } from '@/core/entities'

export const storeSelectedBooks = persistentAtom<Book[]>(
  'storeSelectedBooks',
  [],
  {
    encode(value) {
      return JSON.stringify(value)
    },
    decode(value) {
      try {
        return JSON.parse(value)
      } catch (error) {
        return value
      }
    },
  },
)

export const handleSelectBook = (book: Book) => {
  if (
    storeSelectedBooks.get().some((storedBook) => storedBook.id === book.id)
  ) {
    storeSelectedBooks.set(
      storeSelectedBooks
        .get()
        .filter((storedBook) => storedBook.id !== book.id),
    )

    return
  }
  storeSelectedBooks.set([...storeSelectedBooks.get(), book])
}

export const setSelectedBooks = (books: Book[]) => {
  storeSelectedBooks.set(books)
}
