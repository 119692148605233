import { HistoryList, StudentTabs } from '@/components'

function StudentMain() {
  return (
    <>
      <StudentTabs />

      <HistoryList />
    </>
  )
}

export default StudentMain
