function ReadingReport() {
  return (
    <div>
      <div class="mt-4 flex flex-row">
        <div class="ml-4 grid w-1/2 grid-cols-2 gap-2">
          <div class="text-base font-semibold text-zinc-600">WCPM</div>
          <div class="text-base font-bold text-zinc-800">28</div>
          <div class="text-base font-semibold text-zinc-600">Words Read</div>
          <div class="text-base font-bold text-zinc-800">12</div>
          <div class="text-base font-semibold text-zinc-600">Sentence Read</div>
          <div class="text-base font-bold text-zinc-800">4</div>
          <div class="text-base font-semibold text-zinc-600">Total Pages</div>
          <div class="text-base font-bold text-zinc-800">26</div>
          <div class="text-base font-semibold text-zinc-600">Pages Read</div>
          <div class="text-base font-bold text-zinc-800">4</div>
          <div class="text-base font-semibold text-zinc-600">Start Time</div>
          <div class="truncate text-base font-bold text-zinc-800">
            Tue Dec 12 2023 <br />
            07:18:3
          </div>
        </div>
        <div class="mr-4 grid w-1/2 grid-cols-2 gap-2">
          <div class="text-base font-semibold text-zinc-600">Reading Time</div>
          <div class="text-base font-bold text-zinc-800">00:50 min:sec</div>
          <div class="text-base font-semibold text-zinc-600">Grade Level</div>
          <div class="text-base font-bold text-zinc-800">Preschool-3</div>
          <div class="text-base font-semibold text-zinc-600">Age</div>
          <div class="text-base font-bold text-zinc-800">5 - 7</div>
          <div class="text-base font-semibold text-zinc-600">
            Book Lexile Oral <br />
            Readability Measure
          </div>
          <div class="text-base font-bold text-zinc-800">700L</div>
          <div class="col-span-2 flex flex-row text-base font-semibold text-zinc-600">
            <div class="mr-4">Progress</div>
            <div
              aria-valuemax="100"
              aria-valuemin="0"
              aria-valuenow="8"
              aria-valuetext="8%"
              role="progressbar"
              data-state="loading"
              data-value="8"
              data-max="100"
              class="relative overflow-hidden bg-gray-200 md:h-auto md:w-2/3 2xl:h-[30px] 2xl:w-full"
              style={{ transform: 'translateZ(0px)' }}
            >
              <div class="absolute z-10 ml-4 text-base font-bold text-white md:my-auto 2xl:my-1">
                8%
              </div>
              <div
                data-state="loading"
                data-value="8"
                data-max="100"
                class="ease-[cubic-bezier(0.65, 0, 0.35, 1)] h-full w-full bg-rose-600 text-base font-bold text-white transition-transform duration-[660ms]"
                style={{ transform: 'translateX(-92%)' }}
              />
            </div>
          </div>
        </div>
      </div>
      <a
        class="z-10 mt-4 w-full rounded-md bg-blue-600 p-2 text-center text-sm text-white"
        href="/session/details"
      >
        See Details
      </a>
    </div>
  )
}

export default ReadingReport
