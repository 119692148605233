const primary = '#3c50e0'
const secondary = '#FF4F8B'
const tertiary = '#7C4BE2'

const black = '#000000'
const white = '#FFFFFF'

const violet = {
  10: '#F7F0FF',
  20: '#E3CFFE',
  30: '#C7A4FC',
  40: '#B27FFF',
  50: '#9B67FF',
  60: '#7C4BE2',
  70: '#6D40CD',
  80: '#653DB8',
  90: '#422582',
  100: '#1A0F33',
}

const paleViolet = {
  10: '#F6F5FF',
  20: '#E2E1FF',
}

const caribbeanGreen = {
  10: '#E7F7F3',
  20: '#A3FFE7',
  30: '#74F2D5',
  40: '#48E6C6',
  50: '#25D9BA',
  60: '#00CCB1',
  70: '#05A595',
  80: '#008077',
  90: '#005956',
  100: '#003333',
}

const pink = {
  10: '#FFEEF2',
  20: '#FFCBDA',
  30: '#FFC9D6',
  40: '#FFA1BA',
  50: '#FF78A0',
  60: '#FF4F8B',
  70: '#D93873',
  80: '#B3255E',
  90: '#8C174A',
  100: '#660F38',
}

const yellow = {
  10: '#FFFFE6',
  20: '#FEFCB3',
  30: '#FFF78A',
  40: '#FFEF61',
  50: '#FFE438',
  60: '#FFD610',
  70: '#D9AE04',
  80: '#B48901',
  90: '#8C6601',
  100: '#664701',
}

const success = {
  10: '#EAF7EB',
  20: '#C1E6C4',
  30: '#98D59F',
  40: '#71C27E',
  50: '#54B56B',
  60: '#34A853',
  70: '#22823F',
  80: '#125C2B',
  90: '#083619',
  100: '#010F07',
}

const warning = {
  10: '#FEFCF0',
  20: '#FFF6D1',
  30: '#FFEBA8',
  40: '#FFDD80',
  50: '#FFCD56',
  60: '#FFB82E',
  70: '#D9941C',
  80: '#B3710C',
  90: '#8C5104',
  100: '#553200',
}

const error = {
  10: '#FFECE4',
  20: '#FFC7B1',
  30: '#FF7A58',
  40: '#FF5235',
  50: '#FF2415',
  60: '#EC0000',
  70: '#CD0000',
  80: '#A90000',
  90: '#830000',
  100: '#54000B',
}

const neutral = {
  10: '#F3F3F3',
  20: '#EBEBEB',
  30: '#DDD',
  40: '#CECECE',
  50: '#A5A5A5',
  60: '#868686',
  70: '#5E5E5E',
  80: '#494949',
  90: '#393939',
  100: '#212121',
}

const main = {
  pink: secondary,
  violet: tertiary,
  yellow: '#FFD610',
  'caribbean-green': primary,
}

const bg = {
  'matte-white': '#EDEFF6',
  disabled: '#FFFFFF',
  violet: '#534CAD',
  'student-card': '#6D40CD4D',
  'onboarding-selected-card': '#b27fff4d',
}

const text = {
  black: '#494949',
  white: '#FFFFFF',
  grey: '#A5A5A5',
  'light-grey': '#CECECE',
  disabled: '#EBEBEB',
  validation: '#FFB82E',
  error: '#FF0A0A',
  success: '#34A853',
}

const gradient = {
  'violet-from': '#E24AFF -29.1%',
  'violet-to': '#524BAD 91.03%',
}

export const colors = {
  'sap-blue': '#3c50e0',

  primary,
  secondary,
  tertiary,

  black,
  white,

  violet,
  'pale-violet': paleViolet,
  'caribbean-green': caribbeanGreen,
  pink,
  yellow,

  'edsm-success': success,
  'edsm-warning': warning,
  'edsm-error': error,

  'edsm-neutral': neutral,

  main,
  bg,
  text,

  'gradient-color': gradient,
}
