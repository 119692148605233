// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { onMount } from 'solid-js'

import { sortDailyReadingTime } from '@/utils'

interface ChatProps {
  canvaid: string
  title: string
  labels?: unknown[]
  data?: unknown[]
}

interface DailyReadingTimeData {
  name: string
  dailyReadingTime: number
  href: string
}

function CharBar(props: ChatProps) {
  let sortOrder = 'asc'
  let rootEl: HTMLDivElement
  let chart: unknown

  onMount(() => {
    /**
     * ---------------------------------------
     * This demo was created using amCharts 4.
     *
     * For more information visit:
     * https://www.amcharts.com/
     *
     * Documentation is available at:
     * https://www.amcharts.com/docs/v4/
     * ---------------------------------------
     */

    // Themes begin
    am4core.useTheme(am4themes_animated as ITheme)
    // Themes end

    /**
     * Chart design taken from Samsung health app
     */

    chart = am4core.create(`${props.canvaid}`, am4charts.XYChart)
    chart.hiddenState.properties.opacity = 0 // this creates initial fade-in

    chart.paddingRight = 40

    chart.scrollbarY = new am4core.Scrollbar({
      wheelable: true,
    })

    chart.data = [
      {
        name: 'Adam Mendez',
        dailyReadingTime: 4.2,
        href: 'https://images.generated.photos/fija-7-z6NysWTVCA7m3yPRPhvGsNrufnxgLyf-tN0k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjE0NzY2LmpwZw.jpg',
      },
      {
        name: 'James Johnson',
        dailyReadingTime: 0.5,
        href: 'https://images.generated.photos/fija-7-z6NysWTVCA7m3yPRPhvGsNrufnxgLyf-tN0k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjE0NzY2LmpwZw.jpg',
      },
      {
        name: 'Rhonda Gilbert',
        dailyReadingTime: 2.7,
        href: 'https://images.generated.photos/fija-7-z6NysWTVCA7m3yPRPhvGsNrufnxgLyf-tN0k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjE0NzY2LmpwZw.jpg',
      },
      {
        name: 'Leonard Morris',
        dailyReadingTime: 3.9,
        href: 'https://images.generated.photos/fija-7-z6NysWTVCA7m3yPRPhvGsNrufnxgLyf-tN0k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjE0NzY2LmpwZw.jpg',
      },
      {
        name: 'Tyler Ford',
        dailyReadingTime: 1.1,
        href: 'https://images.generated.photos/fija-7-z6NysWTVCA7m3yPRPhvGsNrufnxgLyf-tN0k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjE0NzY2LmpwZw.jpg',
      },
      {
        name: 'Douglas Hebert',
        dailyReadingTime: 4.8,
        href: 'https://images.generated.photos/fija-7-z6NysWTVCA7m3yPRPhvGsNrufnxgLyf-tN0k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjE0NzY2LmpwZw.jpg',
      },
      {
        name: 'Brianna Rasmussen',
        dailyReadingTime: 0.3,
        href: 'https://images.generated.photos/fija-7-z6NysWTVCA7m3yPRPhvGsNrufnxgLyf-tN0k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjE0NzY2LmpwZw.jpg',
      },
      {
        name: 'Charles Johnson',
        dailyReadingTime: 2.1,
        href: 'https://images.generated.photos/fija-7-z6NysWTVCA7m3yPRPhvGsNrufnxgLyf-tN0k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjE0NzY2LmpwZw.jpg',
      },
      {
        name: 'Jennifer Wilson',
        dailyReadingTime: 3.5,
        href: 'https://images.generated.photos/fija-7-z6NysWTVCA7m3yPRPhvGsNrufnxgLyf-tN0k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjE0NzY2LmpwZw.jpg',
      },
      {
        name: 'Christina Torres',
        dailyReadingTime: 0.9,
        href: 'https://images.generated.photos/fija-7-z6NysWTVCA7m3yPRPhvGsNrufnxgLyf-tN0k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjE0NzY2LmpwZw.jpg',
      },
      {
        name: 'Mia Turner',
        dailyReadingTime: 4.6,
        href: 'https://images.generated.photos/fija-7-z6NysWTVCA7m3yPRPhvGsNrufnxgLyf-tN0k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjE0NzY2LmpwZw.jpg',
      },
      {
        name: 'Lucas Scott',
        dailyReadingTime: 0.8,
        href: 'https://images.generated.photos/fija-7-z6NysWTVCA7m3yPRPhvGsNrufnxgLyf-tN0k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjE0NzY2LmpwZw.jpg',
      },
      {
        name: 'Zoe Fisher',
        dailyReadingTime: 3.3,
        href: 'https://images.generated.photos/fija-7-z6NysWTVCA7m3yPRPhvGsNrufnxgLyf-tN0k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjE0NzY2LmpwZw.jpg',
      },
      {
        name: 'Ethan Hunt',
        dailyReadingTime: 2.1,
        href: 'https://images.generated.photos/fija-7-z6NysWTVCA7m3yPRPhvGsNrufnxgLyf-tN0k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjE0NzY2LmpwZw.jpg',
      },
      {
        name: 'Sophia Lane',
        dailyReadingTime: 1.7,
        href: 'https://images.generated.photos/fija-7-z6NysWTVCA7m3yPRPhvGsNrufnxgLyf-tN0k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjE0NzY2LmpwZw.jpg',
      },
      {
        name: 'Oliver Grant',
        dailyReadingTime: 0.4,
        href: 'https://images.generated.photos/fija-7-z6NysWTVCA7m3yPRPhvGsNrufnxgLyf-tN0k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjE0NzY2LmpwZw.jpg',
      },
      {
        name: 'Isabella Hart',
        dailyReadingTime: 4.9,
        href: 'https://images.generated.photos/fija-7-z6NysWTVCA7m3yPRPhvGsNrufnxgLyf-tN0k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjE0NzY2LmpwZw.jpg',
      },
      {
        name: 'Liam Stone',
        dailyReadingTime: 2.8,
        href: 'https://images.generated.photos/fija-7-z6NysWTVCA7m3yPRPhvGsNrufnxgLyf-tN0k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjE0NzY2LmpwZw.jpg',
      },
      {
        name: 'Charlotte Reed',
        dailyReadingTime: 1.5,
        href: 'https://images.generated.photos/fija-7-z6NysWTVCA7m3yPRPhvGsNrufnxgLyf-tN0k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjE0NzY2LmpwZw.jpg',
      },
      {
        name: 'Noah Clarke',
        dailyReadingTime: 0.6,
        href: 'https://images.generated.photos/fija-7-z6NysWTVCA7m3yPRPhvGsNrufnxgLyf-tN0k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjE0NzY2LmpwZw.jpg',
      },
      {
        name: 'Ava Brooks',
        dailyReadingTime: 3.7,
        href: 'https://images.generated.photos/fija-7-z6NysWTVCA7m3yPRPhvGsNrufnxgLyf-tN0k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjE0NzY2LmpwZw.jpg',
      },
      {
        name: 'William Davis',
        dailyReadingTime: 1.9,
        href: 'https://images.generated.photos/fija-7-z6NysWTVCA7m3yPRPhvGsNrufnxgLyf-tN0k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjE0NzY2LmpwZw.jpg',
      },
      {
        name: 'Mia Thompson',
        dailyReadingTime: 0.2,
        href: 'https://images.generated.photos/fija-7-z6NysWTVCA7m3yPRPhvGsNrufnxgLyf-tN0k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjE0NzY2LmpwZw.jpg',
      },
      {
        name: 'James Wilson',
        dailyReadingTime: 2.6,
        href: 'https://images.generated.photos/fija-7-z6NysWTVCA7m3yPRPhvGsNrufnxgLyf-tN0k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjE0NzY2LmpwZw.jpg',
      },
      {
        name: 'Amelia Martin',
        dailyReadingTime: 1.1,
        href: 'https://images.generated.photos/fija-7-z6NysWTVCA7m3yPRPhvGsNrufnxgLyf-tN0k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjE0NzY2LmpwZw.jpg',
      },
      {
        name: 'Harper Anderson',
        dailyReadingTime: 4.3,
        href: 'https://images.generated.photos/fija-7-z6NysWTVCA7m3yPRPhvGsNrufnxgLyf-tN0k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjE0NzY2LmpwZw.jpg',
      },
    ] as DailyReadingTimeData[]

    chart.mouseWheelBehavior = 'panY'

    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis())
    categoryAxis.dataFields.category = 'name'
    categoryAxis.renderer.grid.template.strokeOpacity = 0
    categoryAxis.renderer.minGridDistance = 10
    categoryAxis.renderer.labels.template.dx = -40
    categoryAxis.renderer.minWidth = 120
    categoryAxis.renderer.tooltip.dx = -40

    const valueAxis = chart.xAxes.push(new am4charts.ValueAxis())
    valueAxis.renderer.inside = true
    valueAxis.renderer.labels.template.fillOpacity = 0.3
    valueAxis.renderer.grid.template.strokeOpacity = 0
    valueAxis.min = 0
    valueAxis.cursorTooltipEnabled = false
    valueAxis.renderer.baseGrid.strokeOpacity = 0
    valueAxis.renderer.labels.template.dy = 20

    const series = chart.series.push(new am4charts.ColumnSeries())
    series.dataFields.valueX = 'dailyReadingTime'
    series.dataFields.categoryY = 'name'
    series.tooltipText = '{valueX.value}'
    series.tooltip.pointerOrientation = 'vertical'
    series.tooltip.dy = -30
    series.columnsContainer.zIndex = 100

    const columnTemplate = series.columns.template
    columnTemplate.height = am4core.percent(50)
    columnTemplate.maxHeight = 50
    columnTemplate.column.cornerRadius(60, 10, 60, 10)
    columnTemplate.strokeOpacity = 0

    series.heatRules.push({
      target: columnTemplate,
      property: 'fill',
      dataField: 'valueX',
      min: am4core.color('#e5dc36'),
      max: am4core.color('#5faa46'),
    })
    series.mainContainer.mask = undefined

    const cursor = new am4charts.XYCursor()
    chart.cursor = cursor
    cursor.lineX.disabled = true
    cursor.lineY.disabled = true
    cursor.behavior = 'none'

    const bullet = columnTemplate.createChild(am4charts.CircleBullet)
    bullet.circle.radius = 30
    bullet.valign = 'middle'
    bullet.align = 'left'
    bullet.isMeasured = true
    bullet.interactionsEnabled = false
    bullet.horizontalCenter = 'right'
    bullet.interactionsEnabled = false

    bullet.states.create('hover')
    const outlineCircle = bullet.createChild(am4core.Circle)
    outlineCircle.adapter.add('radius', function (radius, target) {
      const circleBullet = target.parent
      return circleBullet.circle.pixelRadius + 10
    })

    const image = bullet.createChild(am4core.Image)
    image.width = 60
    image.height = 60
    image.horizontalCenter = 'middle'
    image.verticalCenter = 'middle'
    image.propertyFields.href = 'href'

    image.adapter.add('mask', function (mask, target) {
      const circleBullet = target.parent
      return circleBullet.circle
    })

    let previousBullet
    chart.cursor.events.on('cursorpositionchanged', function () {
      const dataItem = series.tooltipDataItem

      if (dataItem.column) {
        const bullet = dataItem.column.children.getIndex(1)

        if (previousBullet && previousBullet != bullet) {
          previousBullet.isHover = false
        }

        if (previousBullet != bullet) {
          const hs = bullet.states.getKey('hover')
          hs.properties.dx = dataItem.column.pixelWidth
          bullet.isHover = true

          previousBullet = bullet
        }
      }
    })
    chart.events.on('ready', function () {
      categoryAxis.zoomToIndexes(chart.data.length - 5, chart.data.length)
    })
  })

  function sortCategoryAxis() {
    chart.data = sortDailyReadingTime(
      chart.data as DailyReadingTimeData[],
      sortOrder,
    )
    sortOrder = sortOrder === 'asc' ? 'desc' : 'asc'
  }

  return (
    <div class="flex w-full flex-col pt-8">
      <div class="rounded-md bg-white p-6 shadow-md">
        <div class="flex items-center justify-between pb-8">
          <h1 class="text-2xl text-edsm-neutral-90 ">{props.title}</h1>
          <button
            onClick={sortCategoryAxis}
            class="rounded-md bg-violet-60 px-2 py-1 text-xs text-white"
          >
            Sort
          </button>
        </div>
        <div id={props.canvaid} class="h-[600px]" ref={rootEl!} />
      </div>
    </div>
  )
}

export default CharBar
