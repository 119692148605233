import { atom } from 'nanostores'

import type { Book } from '@/core/entities'

import BookMock from './mock.json'

export const storeBookList = atom<Book[]>([])

export const storeLoadingBookList = atom<boolean>(false)

export const loadBookList = (ageRange?: string, searchText?: string) => {
  try {
    storeLoadingBookList.set(true)

    const booksMockList = JSON.parse(
      JSON.stringify(BookMock.books),
    ) as unknown as Book[]

    if (!ageRange && !searchText) {
      storeBookList.set(booksMockList)
      return
    }

    const filteredBooks = booksMockList.filter((book) => {
      return (
        (!ageRange || book.ageRange === ageRange) &&
        (!searchText ||
          book.name.toLowerCase().includes(searchText.toLowerCase()) ||
          book.author.toLowerCase().includes(searchText.toLowerCase()))
      )
    })

    storeBookList.set(filteredBooks)
  } catch (error) {
    console.error(error)
  } finally {
    storeLoadingBookList.set(false)
  }
}
