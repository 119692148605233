import { useStore } from '@nanostores/solid'
import { navigate } from 'astro:transitions/client'
import {
  For,
  Show,
  createEffect,
  createMemo,
  createSignal,
  onMount,
} from 'solid-js'

import { ArrowLeft, BookListItem } from '@/components'
import {
  loadBookList,
  setAssignmentProgress,
  setSelectedBooks,
  storeBookList,
  storeLoadingBookList,
  storeSelectedBooks,
} from '@/store'
import { cn } from '@/utils'

import { colors } from '@/styles/theme/colors'

function BookAssignmentList() {
  const bookList = useStore(storeBookList)
  const loadingBookList = useStore(storeLoadingBookList)
  const selectedBooks = useStore(storeSelectedBooks)

  const [clearSelectionCheckbox, setClearSelectionCheckbox] =
    createSignal<HTMLInputElement | null>(null)
  const [searchText, setSearchText] = createSignal<string>('')

  function continueAssignment() {
    if (!hasSelectedBooks()) return
    setAssignmentProgress({
      name: 'confirmation',
      url: '/assignment/confirmation',
    })
    navigate('/assignment/confirmation')
  }

  function handleBack() {
    setAssignmentProgress({
      name: 'students',
      url: '/assignment',
    })
    navigate('/assignment')
  }

  function handleClearSelection() {
    setSelectedBooks([])
  }

  function handleSearchBooks(text: string) {
    setSearchText(text)
    updateBookList()
  }

  function updateBookList() {
    loadBookList('', searchText())
  }

  const hasSelectedBooks = createMemo(() => selectedBooks().length)

  createEffect(() => {
    const checkboxElement = clearSelectionCheckbox()
    if (checkboxElement) {
      checkboxElement.indeterminate = true
    }
  })

  onMount(() => {
    loadBookList()
  })

  return (
    <div class="flex w-full flex-col gap-6">
      <button
        class={cn(
          'flex h-7 min-h-7 w-7 min-w-7 items-center justify-center rounded-full border border-solid border-primary',
        )}
        onClick={handleBack}
      >
        <ArrowLeft
          class="relative"
          color={colors.primary}
          width={16}
          height={16}
        />
      </button>

      <div
        class={cn(
          'flex w-full flex-col items-start justify-end gap-4',
          'md:flex-row md:items-center',
        )}
      >
        <div class="flex flex-row items-center gap-2">
          <Show when={hasSelectedBooks()}>
            <label for="clear-selection" class="cursor-pointer">
              Clear selection
            </label>
            <input
              id="clear-selection"
              ref={setClearSelectionCheckbox}
              type="checkbox"
              class="checkbox-primary checkbox border-gray-300 transition-all duration-300"
              checked
              onClick={handleClearSelection}
            />
          </Show>
        </div>

        <input
          type="text"
          class={cn(
            'h-9 rounded-lg border border-gray-200 bg-white px-4 transition-all duration-300',
            'focus-visible:outline focus-visible:outline-primary',
          )}
          onInput={(ev) => handleSearchBooks(ev.currentTarget.value)}
          value={searchText()}
          placeholder="Search"
        />
      </div>

      <div
        class={cn('flex w-full flex-wrap items-center justify-center gap-1')}
      >
        <Show
          when={!loadingBookList() && bookList().length}
          fallback={<div>Loading...</div>}
        >
          <For each={bookList()}>
            {(book) => (
              <BookListItem book={book} selectedBooks={selectedBooks()} />
            )}
          </For>
        </Show>
      </div>

      <div class="sticky bottom-0 z-30 -mb-6 flex items-center justify-center bg-white pb-6 pt-4 md:justify-end">
        <button
          class="btn-primary-edsoma h-10 w-48"
          disabled={!hasSelectedBooks()}
          onClick={continueAssignment}
        >
          Continue
        </button>
      </div>
    </div>
  )
}

export default BookAssignmentList
