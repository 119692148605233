function StudentTabs() {
  return (
    <div class="mb-4 flex gap-2">
      <button class="rounded-md bg-blue-600 p-2 text-sm text-white">
        History
      </button>
      <button class="rounded-md p-2 text-sm text-edsm-neutral-100 hover:text-blue-500">
        Oral Redability Test
      </button>
    </div>
  )
}

export default StudentTabs
