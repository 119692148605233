import { z } from 'zod'
import { createSignal } from 'solid-js'
import { navigate } from 'astro:transitions/client'

import { Input, Typography } from '@/components/solid'
import { useAuthentication } from '@/hooks/useAuthentication'

interface ModalElement extends Element {
  showModal: () => void
  close: () => void
}

const schema = z.object({
  email: z.string().email({ message: 'Invalid e-mail address' }),
  password: z.string(),
})

const URI = encodeURIComponent(import.meta.env.PUBLIC_CLEVER_REDIRECT_URI_URL)
const CLEVER_CLIENT_ID = import.meta.env.CLEVER_CLIENT_ID
const CLEVER_DISTRICT_ID = import.meta.env.CLEVER_DISTRICT_ID

const LoginForm = () => {
  const { auth } = useAuthentication()

  const [userEmail, setUserEmail] = createSignal('')
  const [userPassword, setuserPassword] = createSignal('')

  const [loading, setLoading] = createSignal(false)
  const [modalRef, setModalRef] = createSignal<ModalElement>()
  const [error, setError] = createSignal<string>('')

  const handleLogin = async (data: { email: string; password: string }) => {
    console.log(data)
    try {
      setLoading(true)
      modalRef()?.showModal()
      await auth(data)
      return navigate('/home')
    } catch (error) {
      const errorMessage = 'Check your email and password and try again'
      addToast('Invalid credentials', errorMessage)
      setError(errorMessage)
    } finally {
      setLoading(false)
      modalRef()?.close()
    }
  }

  const handleSubmit = () => {
    const result = schema.safeParse({
      email: userEmail(),
      password: userPassword(),
    })

    if (result.success) {
      handleLogin(result.data)
    } else {
      const errorMessage = result.error.format().email?._errors[0] ?? ''
      addToast('Invalid credentials', errorMessage)
      setError(errorMessage)
    }
  }

  return (
    <div class="z-10">
      <div class="flex w-full flex-col items-center justify-center gap-4 sm:gap-10 h-sm:gap-4">
        <Typography element="h1" size="h-sm" weight="bold">
          Log in
        </Typography>

        <div class="flex flex-col items-center justify-center gap-4">
          <div class="flex flex-col items-center">
            <Typography
              element="span"
              size="t-md"
              class="font-medium h-sm:hidden"
            >
              E-mail
            </Typography>
            <Input
              class="placeholder:normal-case"
              name="email"
              placeholder="email@edsoma.com"
              type="text"
              value={userEmail()}
              onChange={(e) => setUserEmail(e.currentTarget.value)}
              oninput={() => setError('')}
              status={error() ? 'error' : undefined}
            />
          </div>

          <div class="flex flex-col items-center">
            <Typography
              element="span"
              size="t-md"
              class="font-medium h-sm:hidden"
            >
              Password
            </Typography>
            <Input
              class="uppercase placeholder:normal-case"
              name="password"
              placeholder="your password"
              type="password"
              value={userEmail()}
              onChange={(e) => setuserPassword(e.currentTarget.value)}
              oninput={() => setError('')}
              status={error() ? 'error' : undefined}
            />
          </div>

          <div class="flex flex-col items-center justify-center gap-4">
            <button
              id="login-button"
              type="submit"
              class="btn-primary-edsoma"
              disabled={loading()}
              onClick={handleSubmit}
            >
              {loading() ? (
                <span class="loading loading-spinner loading-xs" />
              ) : (
                'Sign In'
              )}
            </button>

            <Typography
              element="span"
              size="t-lg"
              weight="medium"
              class="hidden sm:block h-sm:hidden"
            >
              Or
            </Typography>
            <a
              href={`https://clever.com/oauth/authorize?client_id=${CLEVER_CLIENT_ID}&district_id=${CLEVER_DISTRICT_ID}&redirect_uri=${URI}&response_type=code`}
              class="flex h-14 w-60 items-center justify-center gap-2 rounded-full bg-white text-center text-base font-bold text-black shadow-md hover:bg-caribbean-green-10 active:shadow-lg disabled:cursor-not-allowed disabled:opacity-50 h-sm:h-10"
            >
              <img src="/clever-icon.svg" alt="Clever icon" />
              <span>Continue with Clever</span>
            </a>
          </div>
        </div>
      </div>

      <dialog id="modal" ref={(el) => setModalRef(el)} class="modal">
        <div class="modal-box w-auto bg-white p-0">
          <lottie-player
            src="https://lottie.host/5bc7a36c-83a9-431f-ba37-a434590c04ba/3qBcDKFlQV.json"
            background="##ffffff"
            speed="1"
            style={{ width: '150px', height: '150px' }}
            loop
            autoplay
            direction="1"
            mode="normal"
          />
        </div>
      </dialog>
    </div>
  )
}

export default LoginForm
