interface DailyReadingTimeData {
  name: string
  dailyReadingTime: number
  href: string
}

export function sortDailyReadingTime(
  data: DailyReadingTimeData[],
  sortOrder: 'asc' | 'desc' = 'asc',
) {
  return data.sort((a, b) => {
    if (sortOrder === 'asc') {
      return a.dailyReadingTime - b.dailyReadingTime
    }
    return b.dailyReadingTime - a.dailyReadingTime
  })
}
