import { useStore } from '@nanostores/solid'
import { children, Show, type JSX, createSignal, onMount } from 'solid-js'

import { storeAssignment } from '@/store'
import { navigate } from 'astro:transitions/client'

function AssignmentResolver(props: { children: JSX.Element }) {
  const assignment = useStore(storeAssignment)

  const [visible, setVisible] = createSignal<boolean>(false)

  const resolved = children(() => props.children)

  onMount(() => {
    if (
      assignment().progress.url &&
      window.location.pathname !== assignment().progress.url
    ) {
      return navigate(assignment().progress.url)
    }

    setVisible(true)
  })

  return <Show when={visible()}>{resolved()}</Show>
}

export default AssignmentResolver
