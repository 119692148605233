import { ReadingReport } from '@/components'

function BookHistoryItem() {
  return (
    <div class="flex w-full flex-col rounded-md bg-white shadow-md">
      <div class="collapse collapse-arrow">
        <input type="checkbox" />

        <div class="collapse-title flex flex-row items-center justify-between text-xl font-medium">
          <div class="flex flex-row items-center gap-4">
            <img
              src="https://m.media-amazon.com/images/I/91dmOyr3k2L._AC_UF1000,1000_QL80_.jpg"
              alt=""
              class="w-16"
            />
            <p class="text-base text-edsm-neutral-100">
              A Perfect Puddle: The Adventures of Willie an
            </p>
          </div>
          <span class="text-base text-green-500">In Progress</span>
        </div>
        <div class="collapse-content">
          <ReadingReport />
        </div>
      </div>
    </div>
  )
}

export default BookHistoryItem
