function StudentProfile() {
  return (
    <div class="flex justify-center rounded-md bg-white p-8 shadow-md">
      <div class="flex w-full flex-col items-center justify-center">
        <div class="avatar">
          <div class="w-24 rounded-full border border-b-violet-60">
            <img src="https://daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg" />
          </div>
        </div>
        <div class="m-4 text-center">
          <h3 class="text-xl font-bold text-edsm-neutral-100">Violet Doe</h3>
          <span class="text-sm text-edsm-neutral-80">
            1st Grade - 8 Years Old
          </span>
        </div>
        <div class="w-full border-t">
          {/* <div class="mt-2 flex justify-center">
            <span class="mb-1 text-sm font-bold text-edsm-neutral-100">
              DETAILS
            </span>
          </div> */}
          <div class="mt-4">
            <ul class="list-unstyled text-edsm-success-100">
              <li class="mb-2">
                <span class="me-1 font-bold text-edsm-success-100">
                  Lexile Oral Reading Measure:
                </span>
                <span>1190L</span>
              </li>
              <li class="mb-2 pt-1">
                <span class="me-1 font-bold text-edsm-success-100">
                  Books Read: :
                </span>
                <span>15</span>
              </li>
              <li class="mb-2 pt-1">
                <span class="me-1 font-bold text-edsm-success-100">
                  Fluency
                </span>
                <span>87</span>
              </li>
              <li class="mb-2 pt-1">
                <span class="me-1 font-bold text-edsm-success-100">
                  DTV Avg:
                </span>
                <span>1 Min</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StudentProfile
