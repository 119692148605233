// import { jwtDecode } from 'jwt-decode'

// import { login } from '@/core/infra/fetch'
// import { getStudent } from '@/core/infra/graphql'
import { deleteCookie, setCookie } from '@/utils'

interface LoginResponse {
  accessToken: string
  expiresIn: number
}

// interface LoadUserProps {
//   jwtUser: App.Locals['jwtUser']
//   expiresIn: number
// }

interface UserAuthData {
  email: string
  password: string
}

const fakeLogin = (data: UserAuthData): Promise<LoginResponse> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (data.email === 'teach@edsoma.com' && data.password === '123456') {
        resolve({
          accessToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9',
          expiresIn: 86400,
        })
      } else {
        reject()
      }
    }, 1000)
  })
}
const handleLogin = async (data: UserAuthData): Promise<LoginResponse> => {
  const { accessToken, expiresIn } = await fakeLogin(data)

  console.log(accessToken, expiresIn)

  setCookie('authorization', accessToken, {
    expires: new Date(Date.now() + 1000 * 60 * expiresIn),
  })

  return { accessToken, expiresIn }
}

// const loadUserInfo = async ({ jwtUser, expiresIn }: LoadUserProps) => {
//   if (!jwtUser?.id) return

//   const response = await getStudent(jwtUser?.id)
//   setCookie('user', JSON.stringify(response.child), {
//     expires: new Date(Date.now() + 1000 * 60 * expiresIn),
//   })
// }

export const useAuthentication = () => {
  const auth = async (data: UserAuthData) => {
    try {
      await handleLogin(data)
      //const jwtUser = jwtDecode<App.Locals['jwtUser']>(accessToken)
      //await Promise.all([loadUserInfo({ expiresIn, jwtUser })])
    } catch (error) {
      deleteCookie('authorization')
      deleteCookie('user')
      console.error(error)
      throw new Error(error as string)
    }
  }

  const signOut = (cb?: () => void) => {
    localStorage.removeItem('suggestions:onboarding')
    deleteCookie('authorization')
    deleteCookie('user')
    if (cb) cb()
  }

  return { auth, signOut }
}
