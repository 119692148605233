import { useAuthentication } from '@/hooks/useAuthentication'
import { navigate } from 'astro:transitions/client'
import { IoSearch } from 'solid-icons/io'
import { FaSolidAngleDown } from 'solid-icons/fa'
import { FiMenu } from 'solid-icons/fi'

function MainSearchBar() {
  const { signOut } = useAuthentication()

  return (
    <div class="sticky top-0 z-30 backdrop-blur-md">
      <nav class="mt-4 flex w-full items-center gap-4 rounded-md bg-white px-4 shadow-lg">
        <label
          for="side-menu-drawer"
          class="drawer-button cursor-pointer lg:hidden"
          data-drawer-target="logo-sidebar"
          data-drawer-toggle="logo-sidebar"
          aria-controls="logo-sidebar"
        >
          <FiMenu />
        </label>

        <IoSearch />

        <input
          class="h-14 w-full flex-grow-0 appearance-none border-0	bg-white ring-0 focus:outline-none focus:ring-0"
          placeholder="Search"
        />
        <div class="dropdown dropdown-end dropdown-bottom">
          <div
            tabIndex={0}
            role="button"
            class="m-1 flex w-full items-center justify-center gap-2"
          >
            <span class="hidden text-right lg:block">
              <span class="block whitespace-nowrap text-sm font-medium text-black">
                Thomas Anree
              </span>
              <span class="block whitespace-nowrap text-xs font-medium">
                Teacher
              </span>
            </span>
            <div class="avatar">
              <div class="h-8 w-8 rounded-full">
                <img src="https://daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg" />
              </div>
            </div>
            <FaSolidAngleDown />
          </div>
          <ul
            tabIndex={0}
            class="menu dropdown-content z-[1] w-52 rounded-box  bg-white p-2 shadow-md"
          >
            <li>
              <a>My Profile</a>
            </li>
            <li>
              <a onClick={() => signOut(() => navigate('/login'))}>Log Out</a>
            </li>
          </ul>
        </div>

        <label class="swap swap-rotate">
          <input type="checkbox" class="theme-controller" value="dark" />

          <svg
            class="swap-off h-6 w-6 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z" />
          </svg>
          <svg
            class="swap-on h-6 w-6 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z" />
          </svg>
        </label>
      </nav>
    </div>
  )
}

export default MainSearchBar
