interface CookieOptions {
  expires?: Date | string | number
  path?: string
  domain?: string
  secure?: boolean
  sameSite?: 'strict' | 'lax' | 'none'
}

export const setCookie = (
  name: string,
  value: string,
  options?: CookieOptions,
): void => {
  const optionsKeys = Object.keys(options || {}) as Array<keyof CookieOptions>
  const optionsString = optionsKeys.reduce((acc, key) => {
    const value = options?.[key]
    if (value) {
      return `${acc}; ${key}=${value}`
    }
    return acc
  }, '')

  document.cookie = `${name}=${value}${optionsString}`
}

export const getCookie = (name: string): string | undefined => {
  const cookies = document.cookie
  const cookiesArray = cookies.split(';')
  const cookie = cookiesArray.find((cookie) => cookie.includes(name))
  if (cookie) {
    const [, value] = cookie.split(`${name}=`)
    return value
  }
  return undefined
}

export const deleteCookie = (name: string): void => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`
}
