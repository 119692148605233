import { BookHistoryItem } from '@/components'

function HistoryList() {
  return (
    <div class="flex flex-col gap-2">
      {Array.from({ length: 10 }, () => (
        <BookHistoryItem />
      ))}
    </div>
  )
}

export default HistoryList
