import { createMemo, Show } from 'solid-js'
import { navigate } from 'astro:transitions/client'

import { Typography } from '@/components'
import type { Student } from '@/core/entities'
import { handleSelectStudent } from '@/store'
import { cn } from '@/utils'

function StudentListItem(props: {
  student: Student
  selectable?: boolean
  selectedStudents?: Student[]
}) {
  const isSelected = createMemo(() => {
    if (!props.selectable || !props.selectedStudents) return false

    return props.selectedStudents.some(
      (student) => student.id === props.student.id,
    )
  })

  const studentLink = createMemo(() => `/students/${props.student.id}`)

  function handleImageError(ev: Event) {
    const img = ev.currentTarget as HTMLImageElement
    img.src =
      'https://images.generated.photos/fija-7-z6NysWTVCA7m3yPRPhvGsNrufnxgLyf-tN0k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjE0NzY2LmpwZw.jpg'
    img.onerror = null
  }

  function handleClickAction() {
    if (!props.selectable) return navigate(studentLink())
    toggleSelectStudent()
  }

  function toggleSelectStudent() {
    handleSelectStudent(props.student)
  }

  return (
    <div
      onClick={handleClickAction}
      style={{ 'border-color': props.student.studentGroup?.colorCode || '' }}
      class={cn(
        'relative flex w-full cursor-pointer flex-col items-center gap-4 rounded-2xl bg-white px-8 py-4 md:h-20 md:flex-row md:py-0',
        'hover:bg-gray-100',
        { border: props.student.studentGroup?.colorCode },
      )}
    >
      <Show when={props.selectable}>
        <input
          type="checkbox"
          class={cn(
            'checkbox-primary checkbox absolute right-2 top-2 border-gray-300 transition-all duration-300 md:static',
            'hover:scale-105 hover:border-gray-600',
          )}
          checked={isSelected()}
        />
      </Show>

      <div class="flex flex-1 flex-row items-center gap-2">
        <div
          class="h-14 min-h-14 w-14 min-w-14 overflow-hidden rounded-full border-4 border-gray-200"
          style={{
            'border-color': props.student.studentGroup?.colorCode || '',
          }}
        >
          <img
            src={props.student.imageUrl || ''}
            alt={props.student.name}
            onError={(ev) => handleImageError(ev)}
            class={cn(
              'h-full w-full rounded-full border-2 border-white object-cover transition-all duration-300',
              'hover:scale-125',
            )}
          />
        </div>

        <Typography
          element="span"
          size="t-xl"
          weight="normal"
          class="text-neutral-950"
        >
          {props.student.name}
        </Typography>
      </div>

      <div class="flex w-full flex-1 flex-row items-center md:w-auto">
        <div class="flex flex-1 flex-col items-center">
          <Typography
            element="span"
            size="t-md"
            weight="bold"
            class="whitespace-nowrap text-neutral-800"
          >
            {props.student.age}
          </Typography>
          <Typography
            element="span"
            size="t-md"
            class="whitespace-nowrap text-neutral-600"
          >
            Age
          </Typography>
        </div>

        <div class="flex flex-1 flex-col items-center">
          <Typography
            element="span"
            size="t-md"
            weight="bold"
            class="whitespace-nowrap text-neutral-800"
          >
            {props.student.grade}
          </Typography>
          <Typography
            element="span"
            size="t-md"
            class="whitespace-nowrap text-neutral-600"
          >
            Grade
          </Typography>
        </div>

        <div class="flex flex-1 flex-col items-center">
          <Typography
            element="span"
            size="t-md"
            weight="bold"
            class="whitespace-nowrap text-neutral-800"
          >
            {props.student.readingMetrics.lexileLevel}
          </Typography>
          <Typography
            element="span"
            size="t-md"
            class="whitespace-nowrap text-neutral-600"
          >
            Lexile Oral
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default StudentListItem
