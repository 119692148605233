import { action, atom } from 'nanostores'
import { persistentAtom } from '@nanostores/persistent'

import type { StudentGroup } from '@/core/entities'

import StudentsMock from './mock.json'

export const storeStudentGroupList = persistentAtom<StudentGroup[]>(
  'studentGroupList',
  [],
  {
    encode(value) {
      return JSON.stringify(value)
    },
    decode(value) {
      try {
        return JSON.parse(value)
      } catch (error) {
        return value
      }
    },
  },
)

export const storeLoadingStudentGroupList = atom<boolean>(false)

export const getStudentGroupList = action(
  storeStudentGroupList,
  'getStudentGroupList',
  async (store) => {
    if (store.get().length) return

    try {
      storeLoadingStudentGroupList.set(true)
      store.set(StudentsMock.studentGroups as unknown as StudentGroup[])
    } catch (error) {
      console.error(error)
    } finally {
      storeLoadingStudentGroupList.set(false)
    }
  },
)
