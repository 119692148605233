import { action, atom } from 'nanostores'
import { persistentAtom } from '@nanostores/persistent'

import type { Student } from '@/core/entities'

import StudentsMock from './mock.json'

export const storeStudentList = persistentAtom<Student[]>('studentList', [], {
  encode(value) {
    return JSON.stringify(value)
  },
  decode(value) {
    try {
      return JSON.parse(value)
    } catch (error) {
      return value
    }
  },
})

export const storeLoadingStudentList = atom<boolean>(false)

export const getStudentList = action(
  storeStudentList,
  'getStudentList',
  async (store) => {
    if (store.get().length) return

    try {
      storeLoadingStudentList.set(true)
      store.set(StudentsMock.students as unknown as Student[])
    } catch (error) {
      console.error(error)
    } finally {
      storeLoadingStudentList.set(false)
    }
  },
)
