import { action } from 'nanostores'
import { persistentAtom } from '@nanostores/persistent'

import { storeSelectedStudents } from '@/store'

type AssignmentProgress =
  | { name: 'students'; url: '/assignment' }
  | { name: 'books'; url: '/assignment/books' }
  | { name: 'config'; url: '/assignment/config' }
  | { name: 'confirmation'; url: '/assignment/confirmation' }

type Assignment = {
  progress: AssignmentProgress
}

export const storeAssignment = persistentAtom<Assignment>(
  'storeAssignment',
  {
    progress: {
      name: 'students',
      url: '/assignment',
    },
  },
  {
    encode(value) {
      return JSON.stringify(value)
    },
    decode(value) {
      try {
        return JSON.parse(value)
      } catch (error) {
        return value
      }
    },
  },
)

export const setAssignmentProgress = action(
  storeAssignment,
  'setAssignmentProgress',
  (store, progress: AssignmentProgress) => {
    store.set({ progress })
  },
)

export const resetAssignment = action(
  storeAssignment,
  'resetAssignment',
  (store) => {
    store.set({
      progress: {
        name: 'students',
        url: '/assignment',
      },
    })
    storeSelectedStudents.set([])
  },
)
