import { createMemo } from 'solid-js'

import { Typography } from '@/components'
import type { Book } from '@/core/entities'
import { handleSelectBook } from '@/store'
import { cn } from '@/utils'

function BookListItem(props: { book: Book; selectedBooks: Book[] }) {
  const isSelected = createMemo(() => {
    return props.selectedBooks.some(
      (selectedBook) => selectedBook.id === props.book.id,
    )
  })

  function handleImageError(ev: Event) {
    const img = ev.currentTarget as HTMLImageElement
    img.src =
      'https://m.media-amazon.com/images/I/81jlbw9dfGL._AC_UF1000,1000_QL80_.jpg'
    img.onerror = null
  }

  function handleClickAction() {
    toggleSelectBook()
  }

  function toggleSelectBook() {
    handleSelectBook(props.book)
  }

  return (
    <div
      onClick={handleClickAction}
      class={cn(
        'relative flex w-full cursor-pointer flex-col items-center gap-4 rounded-2xl bg-white px-8 py-4 md:h-20 md:flex-row md:py-0',
        'hover:bg-gray-100',
      )}
    >
      <input
        type="checkbox"
        class={cn(
          'checkbox-primary checkbox absolute right-2 top-2 border-gray-300 transition-all duration-300 md:static',
          'hover:scale-105 hover:border-gray-600',
        )}
        checked={isSelected()}
      />

      <div class="flex flex-1 flex-row items-center gap-2">
        <div
          class={cn(
            'h-18 min-w-12 overflow-hidden rounded-md border-2 border-gray-200 transition-all duration-300',
            'hover:scale-125',
          )}
        >
          <img
            src={props.book.image || ''}
            alt={props.book.name}
            onError={(ev) => handleImageError(ev)}
            class={cn('h-full w-full object-cover')}
          />
        </div>

        <Typography
          element="span"
          size="t-xl"
          weight="normal"
          class="text-neutral-950"
        >
          {props.book.name}
        </Typography>
      </div>

      <div class="flex w-full flex-1 flex-row items-center md:w-auto">
        <div class="flex flex-1 flex-col items-center">
          <Typography
            element="span"
            size="t-md"
            weight="bold"
            class="whitespace-nowrap text-neutral-800"
          >
            {props.book.author}
          </Typography>
          <Typography
            element="span"
            size="t-md"
            class="whitespace-nowrap text-neutral-600"
          >
            Author
          </Typography>
        </div>

        <div class="flex flex-1 flex-col items-center">
          <Typography
            element="span"
            size="t-md"
            weight="bold"
            class="whitespace-nowrap text-neutral-800"
          >
            {props.book.ageRange}
          </Typography>
          <Typography
            element="span"
            size="t-md"
            class="whitespace-nowrap text-neutral-600"
          >
            Age range
          </Typography>
        </div>

        <div class="flex flex-1 flex-col items-center">
          <Typography
            element="span"
            size="t-md"
            weight="bold"
            class="whitespace-nowrap text-neutral-800"
          >
            {props.book.lexileLevel}
          </Typography>
          <Typography
            element="span"
            size="t-md"
            class="whitespace-nowrap text-neutral-600"
          >
            Lexile level
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default BookListItem
