import { navigate } from 'astro:transitions/client'
import { useStore } from '@nanostores/solid'
import {
  For,
  Show,
  createEffect,
  createMemo,
  createSignal,
  onMount,
} from 'solid-js'
import { BiSolidGridAlt, BiRegularMenu } from 'solid-icons/bi'
import { FaSolidAngleDown } from 'solid-icons/fa'

import { StudentCard, StudentListItem } from '@/components'
import type { StudentGroup } from '@/core/entities'
import {
  getStudentGroupList,
  getStudentList,
  setAssignmentProgress,
  setSelectedStudents,
  storeLoadingStudentList,
  storeSelectedStudents,
  storeStudentGroupList,
  storeStudentList,
} from '@/store'
import { cn } from '@/utils'

type ItemsShowType = 'card' | 'list'

function StudentList() {
  const loadingStudentList = useStore(storeLoadingStudentList)
  const selectedStudents = useStore(storeSelectedStudents)
  const studentGroupList = useStore(storeStudentGroupList)
  const studentList = useStore(storeStudentList)

  const [clearSelectionCheckbox, setClearSelectionCheckbox] =
    createSignal<HTMLInputElement | null>(null)
  const [itemsShowType, setItemsShowType] = createSignal<ItemsShowType>('list')
  const [searcText, setSearchText] = createSignal<string>('')
  const [selectedGroup, setSelectedGroup] = createSignal<StudentGroup | null>(
    null,
  )

  function selectGroup(group: StudentGroup | null = null) {
    setSelectedGroup(group)
    const activeElement = document.activeElement as HTMLElement
    if (activeElement) activeElement.blur()
  }

  function toggleItemsShowType() {
    setItemsShowType((prev) => (prev === 'card' ? 'list' : 'card'))
  }

  function handleSelectAll() {
    if (!hasSelectedStudents()) {
      setSelectedStudents(studentList())
      return
    }

    setSelectedStudents([])
  }

  function continueAssignment() {
    if (!hasSelectedStudents()) return
    setAssignmentProgress({ name: 'books', url: '/assignment/books' })
    navigate('/assignment/books')
  }

  const filteredStudentList = createMemo(() => {
    if (!searcText() && !selectedGroup()) return studentList()

    return studentList().filter((student) => {
      const search = new RegExp(searcText(), 'i')
      return (
        search.test(student.name) &&
        (!selectedGroup() || student.studentGroup?.id === selectedGroup()?.id)
      )
    })
  })

  const hasSelectedStudents = createMemo(() => selectedStudents().length)

  createEffect(() => {
    const checkboxElement = clearSelectionCheckbox()
    if (checkboxElement) {
      checkboxElement.indeterminate = true
    }
  })

  onMount(() => {
    try {
      getStudentList()
      getStudentGroupList()
    } catch (error) {
      console.error(error)
    }
  })

  return (
    <Show
      when={!loadingStudentList() && studentList().length}
      fallback={<div>Loading...</div>}
    >
      <div class="flex w-full flex-col gap-6">
        <div
          class={cn(
            'flex w-full flex-col items-start justify-end gap-4',
            'md:flex-row md:items-center',
          )}
        >
          <div class="flex flex-row items-center gap-2">
            <Show
              when={hasSelectedStudents()}
              fallback={
                <>
                  <label for="select-all" class="cursor-pointer">
                    Select all
                  </label>
                  <input
                    id="select-all"
                    type="checkbox"
                    class="checkbox-primary checkbox border-gray-300 transition-all duration-300"
                    onClick={handleSelectAll}
                  />
                </>
              }
            >
              <label for="clear-selection" class="cursor-pointer">
                Clear selection
              </label>
              <input
                id="clear-selection"
                ref={setClearSelectionCheckbox}
                type="checkbox"
                class="checkbox-primary checkbox border-gray-300 transition-all duration-300"
                checked
                onClick={handleSelectAll}
              />
            </Show>
          </div>

          <input
            type="text"
            class={cn(
              'h-9 rounded-lg border border-gray-200 bg-white px-4 transition-all duration-300',
              'focus-visible:outline focus-visible:outline-primary',
            )}
            onInput={(ev) => setSearchText(ev.currentTarget.value)}
            value={searcText()}
            placeholder="Search"
          />

          <div
            class={cn(
              'dropdown dropdown-end h-9 w-52 rounded-lg border border-gray-200 px-2 transition-all duration-300',
              'focus-within:outline focus-within:outline-1 focus-within:outline-primary',
            )}
          >
            <div
              tabIndex={0}
              role="button"
              class="m-1 flex w-full items-center justify-center gap-2"
            >
              <div class="flex w-full flex-row items-center justify-between gap-2">
                {selectedGroup() ? (
                  <>
                    <div
                      class="h-4 w-4 rounded-md"
                      style={{ 'background-color': selectedGroup()?.colorCode }}
                    />
                    <span class="whitespace-nowrap">
                      {selectedGroup()?.name}
                    </span>
                  </>
                ) : (
                  <span class="whitespace-nowrap">Select Group</span>
                )}
                <FaSolidAngleDown />
              </div>
            </div>
            <ul class="dropdown-content z-[1] w-52 rounded-box  bg-white p-2 shadow-md">
              <li>
                <button
                  class={cn(
                    'flex w-full flex-row items-center gap-2 rounded-md p-2',
                    'focus-visible:outline focus-visible:outline-1 focus-visible:outline-primary',
                  )}
                  onClick={() => selectGroup(null)}
                >
                  None
                </button>
              </li>
              <For each={studentGroupList()}>
                {(group) => (
                  <li>
                    <button
                      class={cn(
                        'flex w-full flex-row items-center gap-2 rounded-md p-2',
                        'focus-visible:outline focus-visible:outline-1 focus-visible:outline-primary',
                      )}
                      onClick={() => selectGroup(group)}
                    >
                      <div
                        class="h-4 w-4 rounded-md"
                        style={{ 'background-color': group.colorCode }}
                      />
                      {group.name}
                    </button>
                  </li>
                )}
              </For>
            </ul>
          </div>

          <div class="join shadow-md">
            <input
              id="card-type"
              class="peer/grid hidden"
              type="radio"
              name="itemsShowType"
              aria-label="Grid"
              checked={itemsShowType() === 'card'}
              onClick={toggleItemsShowType}
            />
            <label
              for="card-type"
              class="flex h-9 w-9 cursor-pointer items-center justify-center rounded-l bg-gray-100 peer-checked/grid:bg-white"
            >
              <BiSolidGridAlt />
            </label>

            <input
              id="list-type"
              class="peer/list hidden"
              type="radio"
              name="itemsShowType"
              aria-label="List"
              checked={itemsShowType() === 'list'}
              onClick={toggleItemsShowType}
            />
            <label
              for="list-type"
              class="flex h-9 w-9 cursor-pointer items-center justify-center rounded-r bg-gray-100 p-1 peer-checked/list:bg-white"
            >
              <BiRegularMenu />
            </label>
          </div>
        </div>

        <div
          class={cn(
            'flex w-full flex-wrap items-center justify-center',
            itemsShowType() === 'card' ? 'gap-4' : 'gap-1',
          )}
        >
          <For each={filteredStudentList()}>
            {(student) => (
              <Show
                when={itemsShowType() === 'card'}
                fallback={
                  <StudentListItem
                    student={student}
                    selectable
                    selectedStudents={selectedStudents()}
                  />
                }
              >
                <StudentCard
                  student={student}
                  selectable
                  selectedStudents={selectedStudents()}
                />
              </Show>
            )}
          </For>
        </div>

        <div class="sticky bottom-0 z-30 -mb-6 flex items-center justify-center bg-white pb-6 pt-4 md:justify-end">
          <button
            class="btn-primary-edsoma h-10 w-48"
            disabled={!hasSelectedStudents()}
            onClick={continueAssignment}
          >
            Continue
          </button>
        </div>
      </div>
    </Show>
  )
}

export default StudentList
