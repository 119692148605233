import {
  type Component,
  type ComponentProps,
  type JSX,
  splitProps,
  createSignal,
} from 'solid-js'

import { cn } from '@/utils'

interface InputProps extends ComponentProps<'input'> {
  icon?: JSX.Element
  status?: 'alert' | 'error' | 'success'
}

const Input: Component<InputProps> = (_props) => {
  const [props, rest] = splitProps(_props, ['class', 'icon', 'status'])

  const [rootElement, setRootElement] = createSignal<HTMLElement>()

  const handleFocusInput = () => {
    rootElement()?.querySelector('input')?.focus()
  }

  return (
    <div
      class={cn(
        'inline-flex h-12 items-center gap-3 rounded-lg border border-solid border-edsm-neutral-40 bg-white px-3 transition delay-150 duration-300',
        'focus-within:border-violet-60 focus-within:shadow-element-focus',
        {
          'border-edsm-error-60': props.status === 'error',
          'border-caribbean-green-60': props.status === 'success',
          'border-edsm-warning-60': props.status === 'alert',
        },
        rest.disabled ? 'cursor-not-allowed bg-edsm-neutral-10' : '',
      )}
      onClick={handleFocusInput}
      ref={setRootElement}
    >
      {props.icon ? <div>{props.icon}</div> : ''}
      <input
        class={cn(
          'h-9 w-60 max-w-xs rounded-lg bg-transparent text-center font-medium text-black placeholder-gray-700 outline-none',
          props.class,
          rest.disabled
            ? 'cursor-not-allowed text-edsm-neutral-50 placeholder-edsm-neutral-50'
            : '',
        )}
        {...rest}
      />
      {props.status ? (
        <div class="h-4 w-4">
          <img src="/danger-circle.svg" alt="Danger icon" />
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default Input
